@import '@/scss/abstract/variables';

/* 
    ==============================================================================================
                                        APOLLO 21 Dev, Listen up!
                                Only use app.scss to define global styles

                                                THANK YOU
    ==============================================================================================
*/

// Button size for buttons icon only
.btn.icon-only {
  display: inline-block;
  min-width: 32px;
  height: 32px;
}

// General style for button with icon and text
.btn {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    height: 20px;
    width: 20px;
  }
  .icon ~ span {
    margin-left: 0.5rem;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: '';
}

@import '@/scss/abstract/variables';

.confirmation-modal {
  color: getColor(gray-7);

  .dialog {
    width: 500px;
  }

  white-space: pre-wrap;
  .title {
    font-size: 20px;
    font-weight: 500;
  }

  .desc {
    font-size: 14px;
    font-weight: 400;
    margin-top: 10px;
  }

  .action-button {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 35px;
    justify-content: flex-end;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-content {
    border-radius: 4px;
  }
}

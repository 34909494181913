//@import "./temp.scss"; //need to remove later, Just for initiate project
@import '@/scss/abstract/_variables';
@import '@/scss/layout/_layout';
@import '@/scss/custom.scss'; // custom from bootstrap
@import '@/scss/font.scss';
@import '@/scss/app.scss';

html {
  font-size: 14px; // This will define the rem multiplier for the entire aplpication;
}

body {
  font-family: 'Kanit', 'Prompt', sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}

.file-input {
  font-size: 14px;
  font-weight: 400;

  .form-control {
    border-radius: 4px 0 0 4px;
    cursor: pointer;
  }

  .input-group-text {
    width: 100px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    background-color: getColor(gray-2);
  }
}

.dropdown {
  &-menu {
    border-radius: 4px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border: 0;

    a {
      color: getColor(gray-7);

      &:active {
        color: white;
      }

      &:hover {
        color: getColor(primary-blue-5);
        background-color: getColor(primary-blue-1);
      }
    }
  }

  &-toggle {
    border: 1px solid getColor(gray-2);

    &:hover,
    &:focus,
    &:active {
      border: 1px solid getColor(gray-2);
    }
  }

  &-toggle-custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid getColor(gray-2);
    color: getColor(gray-7);
    text-decoration: none;
    padding: 7.5px 12px;
    border-radius: 4px;
    font-weight: 400;
    width: max-content;

    &:hover,
    &:focus,
    &:active {
      color: getColor(gray-7);
      border: 1px solid getColor(gray-2);
    }
  }
}

.spinner-border {
  color: getColor(gray-7);
}

.active-on {
  background-color: getColor(light-green-7);
}

.active-off {
  background-color: getColor(pink-5);
}

.searchbox-wrapper {
  height: 2.5rem;
  width: 100%;
  margin: 0 0 28px 0;
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  align-items: center;

  span {
    color: getColor(gray-5);
  }

  &__add {
    gap: 2px;
    width: 140px;
    border: 1px solid getColor(primary-blue-5);
    color: getColor(primary-blue-5);
  }

  &__add:hover {
    background-color: getColor(primary-blue-5);
    color: white;
  }
}

.management-wrapper {
  .header {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    color: getColor(grey-7);
  }

  .table {
    margin-top: 1rem;
  }
}

.user-info-modal {
  .modal-content {
    border-radius: 4px;
  }

  .header {
    font-size: 20px;
    font-weight: 400;
    margin-top: 18px;
  }

  table {
    margin-top: 30px;
    width: 100%;

    tr {
      td:first-child {
        width: 30%;
        color: getColor(gray-5);
      }

      td {
        color: getColor(gray-7);
        padding: 10px 0;
        vertical-align: top;
      }
    }
  }
}

@import '@/scss/abstract/variables';

.navbar {
  z-index: 1;
  color: $dark-gray-text;
  height: $navbar-height;
  background: getColor(light-gray-accent-0);
  box-shadow: $shadow-navbar;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 100vw;
    z-index: 3;

    &__left {
      width: $sidebar-width;
      display: flex; // Flex inside left container
      align-items: center;

      .hamburger-menu {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        width: 40px;
        height: 40px;

        svg {
          fill: getColor(gray-6);
          width: 24px;
          height: 24px;

          &:hover,
          &:active {
            fill: $primary;
            color: $primary;
          }
        }
      }

      h3 {
        margin-top: 10px;
        margin-left: 5px;
      }
    }

    &__center {
      // Nothing yet
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      font-weight: 500;

      #budget-year-ddl {
        margin-right: 10px;
      }

      .user-profile {
        cursor: pointer;
        height: 40px;
        width: 40px;

        &:hover,
        &:active {
          fill: $primary;
          color: $primary;
        }

        &-toggle {
          font-weight: 600;
        }
      }
    }

    .dropdown {
      .btn-custom {
        color: getColor(gray-7);
        text-decoration: none;
        background: transparent;
        border: 0;
        text-decoration: none;
        font-weight: 500;
      }

      .dropdown-menu {
        margin-top: 12px;
        width: 190px;

        .dropdown-item {
          height: 40px;
          display: flex;
          align-items: center;

          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
    }

    .round-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 40px;

      &:hover {
        background-color: getColor(gray-1);
      }
    }
  }
}

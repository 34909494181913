@import '@/scss/abstract/variables.scss';

.app {
  display: flex;
  height: 100%;
}

.app-container {
  flex: auto;
  background-color: white;
  overflow: auto;
  height: calc(100vh - $navbar-height);
}

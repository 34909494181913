:root {
  // Theme
  --dark-purple: #6f52ed; // used as button hover
  --dark-purple2: #5b0eeb; // used as button active
  --light-purple: #9a86ef;
  --light-purple2: #eae8ff;
  --primary-disabled: #d7cdff;

  // White
  --white: #ffffff;
  --lightwhite-primary: #fefeff;

  // Grey
  --lightgrey-1: #c4c4c4;
  --dark-text: #151b26;
  --darkgrey-text: #6f7782;

  --bs-body-color: var(--dark-text);

  // Gradient
  --gradient-main: linear-gradient(135deg, #d585f3 0%, #6615ff 100%);
}

// Variables

// Shadows
$shadow-1: 0px 5px 10px rgba(111, 82, 237, 0.25);
$button-shadow: 1px 2px 6px rgba(138, 112, 244, 0.75);
$button-shadow-large: 1px 2px 10px 1px rgba(138, 112, 244, 0.85);
$shadow-grey: 0px 2px 4px rgba(0, 0, 0, 0.25);

$shadow-navbar: 0px 1px 5px rgba(65, 65, 65, 0.25);

// Navs
$navbar-height: 64px;
$sidebar-width: 17.143rem;

// Colors
$palette-map: (
  primary-blue-0: hsl(201, 100%, 98%),
  primary-blue-1: hsl(201, 100%, 94%),
  primary-blue-2: hsl(201, 100%, 75%),
  primary-blue-3: hsl(201, 100%, 66%),
  primary-blue-4: hsl(201, 100%, 57%),
  primary-blue-5: hsl(201, 100%, 48%),
  primary-blue-6: hsl(201, 100%, 39%),
  primary-blue-7: hsl(201, 100%, 30%),
  primary-blue-8: hsl(201, 100%, 21%),
  primary-blue-9: hsl(201, 100%, 12%),
  red-0: hsl(0, 90%, 98%),
  red-1: hsl(0, 90%, 94%),
  red-2: hsl(0, 90%, 85%),
  red-3: hsl(0, 90%, 76%),
  red-4: hsl(0, 90%, 67%),
  red-5: hsl(0, 90%, 58%),
  red-6: hsl(0, 90%, 49%),
  red-7: hsl(0, 90%, 40%),
  red-8: hsl(0, 90%, 31%),
  red-9: hsl(0, 90%, 22%),
  pink-0: hsl(339, 100%, 98%),
  pink-1: hsl(339, 100%, 94%),
  pink-2: hsl(339, 100%, 85%),
  pink-3: hsl(339, 100%, 76%),
  pink-4: hsl(339, 100%, 67%),
  pink-5: hsl(339, 100%, 58%),
  pink-6: hsl(339, 100%, 49%),
  pink-7: hsl(339, 100%, 40%),
  pink-8: hsl(339, 100%, 31%),
  pink-9: hsl(339, 100%, 22%),
  grape-0: hsl(288, 100%, 98%),
  grape-1: hsl(288, 100%, 94%),
  grape-2: hsl(288, 100%, 85%),
  grape-3: hsl(288, 100%, 76%),
  grape-4: hsl(288, 100%, 67%),
  grape-5: hsl(288, 100%, 58%),
  grape-6: hsl(288, 100%, 49%),
  grape-7: hsl(288, 100%, 40%),
  grape-8: hsl(288, 100%, 31%),
  grape-9: hsl(288, 100%, 22%),
  violet-0: hsl(255, 90%, 98%),
  violet-1: hsl(255, 90%, 94%),
  violet-2: hsl(255, 90%, 85%),
  violet-3: hsl(255, 90%, 76%),
  violet-4: hsl(255, 90%, 67%),
  violet-5: hsl(255, 90%, 58%),
  violet-6: hsl(255, 90%, 49%),
  violet-7: hsl(255, 90%, 40%),
  violet-8: hsl(255, 90%, 31%),
  violet-9: hsl(255, 90%, 22%),
  indigo-0: hsl(230, 90%, 98%),
  indigo-1: hsl(230, 90%, 94%),
  indigo-2: hsl(230, 90%, 85%),
  indigo-3: hsl(230, 90%, 76%),
  indigo-4: hsl(230, 90%, 67%),
  indigo-5: hsl(230, 90%, 58%),
  indigo-6: hsl(230, 90%, 49%),
  indigo-7: hsl(230, 90%, 40%),
  indigo-8: hsl(230, 90%, 31%),
  indigo-9: hsl(230, 90%, 22%),
  blue-0: hsl(208, 90%, 98%),
  blue-1: hsl(208, 90%, 94%),
  blue-2: hsl(208, 90%, 85%),
  blue-3: hsl(208, 90%, 76%),
  blue-4: hsl(208, 90%, 67%),
  blue-5: hsl(208, 90%, 58%),
  blue-6: hsl(208, 90%, 49%),
  blue-7: hsl(208, 90%, 40%),
  blue-8: hsl(208, 90%, 31%),
  blue-9: hsl(208, 90%, 22%),
  cyan-0: hsl(188, 90%, 88%),
  cyan-1: hsl(188, 90%, 84%),
  cyan-2: hsl(188, 90%, 75%),
  cyan-3: hsl(188, 90%, 66%),
  cyan-4: hsl(188, 90%, 57%),
  cyan-5: hsl(188, 90%, 48%),
  cyan-6: hsl(188, 90%, 39%),
  cyan-7: hsl(188, 90%, 30%),
  cyan-8: hsl(188, 90%, 21%),
  cyan-9: hsl(188, 90%, 12%),
  teal-0: hsl(162, 90%, 88%),
  teal-1: hsl(162, 90%, 84%),
  teal-2: hsl(162, 90%, 75%),
  teal-3: hsl(162, 90%, 66%),
  teal-4: hsl(162, 90%, 57%),
  teal-5: hsl(162, 90%, 48%),
  teal-6: hsl(162, 90%, 39%),
  teal-7: hsl(162, 90%, 30%),
  teal-8: hsl(162, 90%, 21%),
  teal-9: hsl(162, 90%, 12%),
  light-green-0: hsl(141, 75%, 99%),
  light-green-1: hsl(141, 75%, 97%),
  light-green-2: hsl(141, 75%, 93%),
  light-green-3: hsl(141, 75%, 84%),
  light-green-4: hsl(141, 75%, 75%),
  light-green-5: hsl(141, 75%, 66%),
  light-green-6: hsl(141, 75%, 57%),
  light-green-7: hsl(141, 75%, 48%),
  light-green-8: hsl(141, 75%, 39%),
  light-green-9: hsl(141, 75%, 30%),
  green-0: hsl(131, 90%, 88%),
  green-1: hsl(131, 90%, 84%),
  green-2: hsl(131, 90%, 75%),
  green-3: hsl(131, 90%, 66%),
  green-4: hsl(131, 90%, 57%),
  green-5: hsl(131, 90%, 48%),
  green-6: hsl(131, 90%, 39%),
  green-7: hsl(131, 90%, 30%),
  green-8: hsl(131, 90%, 21%),
  green-9: hsl(131, 90%, 12%),
  lime-0: hsl(85, 100%, 88%),
  lime-1: hsl(85, 100%, 84%),
  lime-2: hsl(85, 100%, 75%),
  lime-3: hsl(85, 100%, 66%),
  lime-4: hsl(85, 100%, 57%),
  lime-5: hsl(85, 100%, 48%),
  lime-6: hsl(85, 100%, 39%),
  lime-7: hsl(85, 100%, 30%),
  lime-8: hsl(85, 100%, 21%),
  lime-9: hsl(85, 100%, 12%),
  yellow-0: hsl(39, 100%, 98%),
  yellow-1: hsl(39, 100%, 94%),
  yellow-2: hsl(39, 100%, 85%),
  yellow-3: hsl(39, 100%, 76%),
  yellow-4: hsl(39, 100%, 67%),
  yellow-5: hsl(39, 100%, 58%),
  yellow-6: hsl(39, 100%, 49%),
  yellow-7: hsl(39, 100%, 40%),
  yellow-8: hsl(39, 100%, 31%),
  yellow-9: hsl(39, 100%, 22%),
  orange-0: hsl(24, 100%, 98%),
  orange-1: hsl(24, 100%, 94%),
  orange-2: hsl(24, 100%, 85%),
  orange-3: hsl(24, 100%, 76%),
  orange-4: hsl(24, 100%, 67%),
  orange-5: hsl(24, 100%, 58%),
  orange-6: hsl(24, 100%, 49%),
  orange-7: hsl(24, 100%, 40%),
  orange-8: hsl(24, 100%, 31%),
  orange-9: hsl(24, 100%, 22%),
  gray-0: hsl(201, 10%, 98%),
  gray-1: hsl(201, 10%, 91%),
  gray-2: hsl(201, 10%, 78%),
  gray-3: hsl(201, 10%, 69%),
  gray-4: hsl(201, 10%, 60%),
  gray-5: hsl(201, 10%, 51%),
  gray-6: hsl(201, 10%, 42%),
  gray-7: hsl(201, 10%, 33%),
  gray-8: hsl(201, 10%, 24%),
  gray-9: hsl(201, 10%, 19%),
  gray-200: #e9ecef,
  gray-300: #dee2e6,
  gray-400: #ced4da,

  light-gray-0: hsl(0, 0%, 99%),
  light-gray-1: hsl(0, 0%, 98%),
  light-gray-2: hsl(0, 0%, 94%),
  light-gray-3: hsl(0, 0%, 90%),
  light-gray-4: hsl(0, 0%, 86%),
  light-gray-5: hsl(0, 0%, 80%),
  light-gray-6: hsl(0, 0%, 70%),
  light-gray-7: hsl(0, 0%, 61%),
  light-gray-8: hsl(0, 0%, 52%),
  light-gray-9: hsl(0, 0%, 43%),
  // Blue-Gray
  blue-gray-0: hsl(220, 43%, 99%),
  blue-gray-1: hsl(220, 43%, 97%),
  blue-gray-2: hsl(220, 43%, 92%),
  blue-gray-3: hsl(220, 43%, 86%),
  // ************* START OOCA ******************
  back-ground-blue: rgba(205, 216, 225, 0.8),
  black-gardient: linear-gradient(180deg, rgba(71, 76, 85, 0) 0%, rgba(0, 0, 0, 0.9) 100%),
  blue-gardient: linear-gradient(180deg, #e4effb 41.34%, rgba(228, 239, 251, 0) 84.37%),
  //shadow
  elevation-1: 0px 2px 8px rgba(31, 119, 223, 0.04),
  elevation-2: 0px -2px 16px rgba(31, 119, 223, 0.08),
  elevation-3: 0px 8px 16px rgba(31, 119, 223, 0.08),
  elevation-4: 0px 8px 16px rgba(31, 119, 223, 0.08),
  elevation-5: 0px 8px 16px -4px rgba(31, 119, 223, 0.08),
  elevation-6-1: 0px 4px 8px -4px rgba(31, 119, 223, 0.08),
  elevation-6-2: 0px 16px 24px rgba(31, 119, 223, 0.08),
  elevation-7-1: 0px 4px 12px -4px rgba(31, 119, 223, 0.12),
  elevation-7-2: 0px 16px 32px rgba(31, 119, 223, 0.16),
  elevation-8-1: 0px 4px 12px -4px rgba(31, 119, 223, 0.12),
  elevation-8-2: 0px 16px 32px rgba(31, 119, 223, 0.16),
  // ************* END OOCA ******************
);

@function getColor($name) {
  @return map_get($palette-map, $name);
}

$primary: getColor(primary-blue-5);
$dark-gray-text: getColor(gray-7);
$dark-gray-icon: getColor(gray-7);

$danger: getColor(red-5);

$disabled-gray-text: getColor(gray-3);
$sidebar-content: getColor(gray-6);

$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
